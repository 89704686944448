import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string): EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey
    const providerId = this.vm.$route.params.target

    return {
      // pageTitle: 'module.provider-member',
      // search: 'provider-member.search_keyword',
      // filter: {
      //   // member_id: {
      //   //   label: 'provider.data.manager',
      //   //   type: 'selection',
      //   //   options: (meta) => {
      //   //     if(!meta) return []
      //   //     return meta.managerList.map((member:any) => {
      //   //       return {text: member.name, value: member.id}
      //   //     })
      //   //   },
      //   // },
      //   created_at: {
      //     label: 'data.created_at',
      //     type: 'date',
      //   },
      // },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
        isMaster: row.isMaster
      }),
      displayMode: 'table',
      displayModeTypes: ['card', 'table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row.avatar,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'name',
            label: 'data.manager-name',
            type: 'text',
          },
          {
            key: 'email',
            label: 'provider.data.email',
            type: 'text',
          },
          {
            key: 'isMaster',
            label: 'data.main-manager',
            type: 'text',
            text: (row) => {
              return vm.$t(`data.main-manager.${row.isMaster}`)
            },
          },
          {
            key: 'created_at',
            label: "provider.data.created_at",
            type: 'text'
          }
        ],
      },
      // batch: {
      //   delete: {
      //     label: 'action.relieve',
      //     disabled: (row) => {
      //       return row.isMaster
      //     },
      //     targetLabel: row => row.name,
      //   },
      // },
      metaAction: {},
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'member-update', params: { target: row.id } }),
          linkTarget: '_blank',
        },
        relieve: {
          label: 'action.relieve',
          //@ts-ignore
          contentKey: 'name',
          create: (row) => {
            return !row.IsProviderMaster
          },
          component: () => import('modules/member/components/relieve/relieveBtn.vue')
        },
      },
    }

  }
}

export default new listConfig()
