<template>
  <eagle-list :list-key="listKey"></eagle-list>
</template>

<script lang="babel" type="text/babel">
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './providerMemberListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'provider-member-list',
    bindRoute: false,
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      const provider_id = this.$route.params.target
      if(!params.filter) {
        params.filter = {}
      }
      params.filter.provider_id = provider_id
      const res = await this.$api.collection.providerMemberApi.index(params)
      return this.formatData(res.data)
    },
    formatData(data) {
      const formatData = data.map(item => {
        item.isMaster = item.IsProviderMaster
        return item
      })
      return formatData
    },
    // 解綁
    async deleteApi(target, row) {
      const member_id = row.id
      const provider_id = this.$route.params.target
      return await this.$api.collection.providerMemberApi.delete(provider_id, member_id)
    },
    // 批次解綁
    async batchDeleteApi(targets, selectedData) {
      // const pivot_ids = selectedData
      //   .filter(item => item.id == targets)
      //   .map(item => item.provider_pivot.id)
      // return await this.$api.collection.providerMemberApi.batchDelete(pivot_ids)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
